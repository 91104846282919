import { Column, Row } from "@react-tiny-grid/core"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Zoom } from "react-reveal"
import styled from "styled-components"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const Heading = styled.h1`
  text-align: center;
  color: #1c365e;
`

const Feature = styled.div`
  background-color: #ffffffcc;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 100%;
  padding-bottom: 25px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;

  & h3,
  & .text-bold {
    color: #1c365e;
  }

  &:hover {
    color: #fff;
    background-color: #1c365e;
    transform: scale(1.1);

    & h3,
    & .text-bold {
      color: #fff;
    }
  }
`

const FeatureHead = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  min-height: 170px;
`

const HeadText = styled.h3`
  flex: 1;
  text-align: center;
`

const Body = styled.div`
  margin-top: -5px;
  padding: 0 20px;
`

export default function FeaturesPage() {
  return (
    <Layout>
      <Seo title="Features" />
      <Heading>Here some of the core FORSINT Features</Heading>
      <br />

      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/gears-coding.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Cross Platform"
                />
                <HeadText>Cross Platform</HeadText>
              </FeatureHead>
              <Body>
                Eviquire can natively run in Windows, Linux and MacOS. The best
                flexibility ever!
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/cloud-connecting.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Cloud SaaS"
                />
                <HeadText>Cloud SaaS</HeadText>
              </FeatureHead>
              <Body>
                Eviquire is also served as Cloud SaaS, no Installation!
                Compatible with any HTML5 Browser!
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/virtual-machines.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Virtual Machines"
                />
                <HeadText>Virtual Machines</HeadText>
              </FeatureHead>
              <Body>
                Eviquire can run inside a Virtual Machine to comply with the
                best Digital Forensic best practices.
              </Body>
            </Feature>
          </Zoom>
        </Column>
      </Row>

      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/space-journey.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Unlimited Acquisitions"
                />
                <HeadText>Unlimited Acquisitions</HeadText>
              </FeatureHead>
              <Body>
                No matter the plan selected, You can create Unlimited Cases,
                Acquisitions and Evidence.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/contract-handshake.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Floating Subscription"
                />
                <HeadText>Floating Subscription</HeadText>
              </FeatureHead>
              <Body>
                You can use your personal Eviquire license in as many computers
                as you want just once at the time.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/community.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Community License"
                />
                <HeadText>Community License</HeadText>
              </FeatureHead>
              <Body>
                We do believe the community really needs a free Web Forensic
                Tool! Here it is! No Registration needed!
              </Body>
            </Feature>
          </Zoom>
        </Column>
      </Row>

      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/file-box-work.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Case Manager"
                />
                <HeadText>Case Manager</HeadText>
              </FeatureHead>
              <Body>
                The place where you can organize all your Cases, Acquisitions
                and Evidence.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/forensic-report-envelope.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Forensic Reports"
                />
                <HeadText>Forensic Reports</HeadText>
              </FeatureHead>
              <Body>
                Every time a Case, Acquisition or Evidence is closed, a Forensic
                Report is created!
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/case-viewer-search-doc.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Case Viewer"
                />
                <HeadText>Case Viewer</HeadText>
              </FeatureHead>
              <Body>
                Feel free to import and review cases created by others or
                created by you in other machines.
              </Body>
            </Feature>
          </Zoom>
        </Column>
      </Row>

      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/judicial-document-legal.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Judicial Evidence"
                />
                <HeadText>Judicial Evidence</HeadText>
              </FeatureHead>
              <Body>
                Eviquire creates Forensic packages and reports compliant with
                ISO / IEC 27037: 2012 Standard.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/chain-of-custody-encrypted-data-coding.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Chain of Custody"
                />
                <HeadText>Chain of Custody</HeadText>
              </FeatureHead>
              <Body>
                During the entire Acquisition Eviquire diligently maintain the
                Digital Chain of Custody for you.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/hash-analysis.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Files Triple Hash"
                />
                <HeadText>Files Triple Hash</HeadText>
              </FeatureHead>
              <Body>
                All the Evidence are hashed with 3 different algorithms to avoid
                any even remote possibility of collision.
              </Body>
            </Feature>
          </Zoom>
        </Column>
      </Row>

      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/timestamp-text.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Realtime Evidence Timestamp"
                />
                <HeadText>Realtime Evidence Timestamp</HeadText>
              </FeatureHead>
              <Body>
                Each evidence is timestamped in realtime, not at the end of the
                acquisition session. We can&#39;t do better than this.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/multiple-blockchain-network-connection.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Multi Blockchain Timestamp"
                />
                <HeadText>Multi Blockchain Timestamp</HeadText>
              </FeatureHead>
              <Body>
                Striving for perfection, Eviquire timestamps each Forensic
                Package created in multiple Blockchains.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/next-gen-blockchain.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Next Gen Blockchain"
                />
                <HeadText>Next Gen Blockchain</HeadText>
              </FeatureHead>
              <Body>
                The use of latest technologies and blockchains makes
                Evidence&#39;s timestamp fast and scalable.
              </Body>
            </Feature>
          </Zoom>
        </Column>
      </Row>

      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/video-browser-add-teamwork.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Screen Recording"
                />
                <HeadText>Screen Recording</HeadText>
              </FeatureHead>
              <Body>
                The entire Acquisition session is screen recorded to create a
                strong and presentable Evidence.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/global-network-coding.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Network Traffic Capture"
                />
                <HeadText>Network Traffic Capture</HeadText>
              </FeatureHead>
              <Body>
                The traffic during the Acquisition Session is fully captured to
                make the final Evidence even stronger.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/audit.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Activity Audit"
                />
                <HeadText>Activity Audit</HeadText>
              </FeatureHead>
              <Body>
                Eviquire keeps track of user interactions during the acquisition
                session, creating an auditable user activity log.
              </Body>
            </Feature>
          </Zoom>
        </Column>
      </Row>

      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/printable-image-print-text.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="A4 Printable Screenshot"
                />
                <HeadText>A4 Printable Screenshot</HeadText>
              </FeatureHead>
              <Body>
                To facilitate the presentation fo the Evidence, Each Screenshot
                Evidence is printable in A4.
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/auto-scroll-file-raw-photo-image.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Pageshot"
                />
                <HeadText>Pageshot</HeadText>
              </FeatureHead>
              <Body>
                If a Screenshot Evidence is not enough, the Pageshot will
                capture the content of a long page!
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/auto-scroll-operator-robot-robot.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Smart Page Auto-scroll"
                />
                <HeadText>Smart Page Auto-scroll</HeadText>
              </FeatureHead>
              <Body>
                For social media or infinite pages, Eviquire can automatically
                scroll while acquiring the content.
              </Body>
            </Feature>
          </Zoom>
        </Column>
      </Row>

      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/source-code.svg"
                  loading="eager"
                  quality={100}
                  width={100}
                  alt="Source Code MHTML"
                />
                <HeadText>Source Code MHTML</HeadText>
              </FeatureHead>
              <Body>
                Each Web Evidence&#39;s source code is exported and saved in Web
                Page Archive format (MHTML)
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Feature>
              <FeatureHead>
                <StaticImage
                  src="../images/features/vr-presentation.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="VR Ready!"
                />
                <HeadText>VR Ready!</HeadText>
              </FeatureHead>
              <Body>
                Just kidding!..
                <br /> you won&#39;t find this feature in Eviquire... yet! :)
              </Body>
            </Feature>
          </Zoom>
        </Column>

        <Column></Column>
      </Row>
      <br />
    </Layout>
  )
}
